<template>
  <div class="validHouseList-wrapper">
    <!-- <w-room-tree 
      ref="roomTree"
      :lazy="true"
      :style="{zIndex:3000}"
      @loadData="treeLoad"
      :defaultProps="treeProps"
      :visible.sync="openDrawer"
      @current-change="selectNode"
      :treeData="treeData"
    ></w-room-tree> -->
    <list
      ref="list"
      :immediate='false'
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <!-- <v-button text="新增房号" permission="add" @click="create"></v-button> -->
        <v-button text="房号导入" permission="roomImport" @click="goPageImport('validRoomImport')"></v-button>
        <!-- <v-button text="关联第三方导入" permission="thirdPartyImport" @click="goPageImport('validRoomImport')"></v-button> -->
        <v-button text="关联第三方导入" permission="validThirdImport" @click="goPageImport('validThirdPartyImport')"></v-button>
        <v-button text="批量导入" permission="import" @click="goPageImport('validHouseImport')"></v-button>
      </template>
      <!-- <template #tableLeftSlot>
        <div class="tree-group">
          <v-tree
            :data="treeData"
            :defaultProps="treeProps"
            :lazy="true"
            :expand-on-click-node="false"
            @loadData="treeLoad"
            @nodeClick="selectNode"
            @nodeContextMenu="rightClict"
          ></v-tree>
        </div>
      </template> -->

      <template #searchSlot>
        <v-select label="房屋类型" v-model="searchParams.houseType" :options="houseTypeOps"></v-select>
        <v-select label="是否可售" v-model="searchParams.canSell" :options="saleTypeOps"></v-select>
        <v-select label="出售状态" v-model="searchParams.isSell" :options="saleStatusOps"></v-select>
        <v-select label="是否可租" v-model="searchParams.canLease" :options="rentTypeOps"></v-select>
        <v-select label="出租状态" v-model="searchParams.isLease" :options="rentStatusOps"></v-select>
        <v-select label="房屋状态" v-model="searchParams.roomStatus" :options="roomStatusOps"></v-select>
        <v-select label="使用状态" v-model="searchParams.usingStatus" :options="usingStatusOps"></v-select>
        <!-- <v-input label="苑" v-model="searchParams.blockName"></v-input>
        <v-input label="幢" v-model="searchParams.buildingNumber"></v-input>
        <v-input label="单元" v-model="searchParams.unit"></v-input>
        <v-input label="室" v-model="searchParams.room"></v-input> -->
        <!-- <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2> -->
        <v-datepicker
          label="修改时间段"
          :startTime.sync="searchParams.lastUpdateTimeStart"
          :endTime.sync="searchParams.lastUpdateTimeEnd"
          type="rangedatetimer"
        ></v-datepicker>
        <v-select label="是否为子母车位" v-model="searchParams.isParent" :options="isServerCenterOps"></v-select>
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量删除"
          permission="delete"
          @click="batchDelete(scope.selectedData)"
          :disabled="scope.selectedData.ids.length === 0"
        ></v-button>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button text="删除" type="text" permission="delete" @click="singerDelete(scope.row)"></v-button>
      </template>
    </list>
    <div id="rightOpera" ref="operaIsShow" class="rightOpera" :class="{'domHidden': operaIsShow}">
      <li @click="batchEdit">批量修改房号</li>
      <li @click="getNodeFirstList">刷新</li>
    </div>
    <el-dialog title="批量修改" :visible.sync="isBatchEdit" width="598px" class="dialog">
      <div class="formBox">
        <div class="f-left">
          <v-input-number
            label="计费面积:"
            placeholder
            v-model="batchParams.chargingArea"
            v-bind="areaNumParams"
          ></v-input-number>
          <span>㎡</span>
          <v-input-number
            label="建筑面积:"
            placeholder
            v-model="batchParams.buildingArea"
            v-bind="areaNumParams"
          ></v-input-number>
          <span>㎡</span>
          <v-input-number
            label="套内面积:"
            placeholder
            v-model="batchParams.innerArea"
            v-bind="areaNumParams"
          ></v-input-number>
          <span>㎡</span>
          <v-input-number
            label="分摊面积:"
            placeholder
            v-model="batchParams.publicArea"
            v-bind="areaNumParams"
          ></v-input-number>
          <span>㎡</span>
          <v-input-number
            label="辅助面积:"
            placeholder
            v-model="batchParams.assistChargingArea"
            v-bind="areaNumParams"
          ></v-input-number>
          <span>㎡</span>
          <v-input-number
            label="花园面积:"
            placeholder
            v-model="batchParams.gardenArea"
            v-bind="areaNumParams"
          ></v-input-number>
          <span>㎡</span>
          <v-input-number
            label="地下室面积:"
            placeholder
            v-model="batchParams.undergroundArea"
            v-bind="areaNumParams"
          ></v-input-number>
          <span>㎡</span>
          <v-input-number
            label="统计面积:"
            placeholder
            v-model="batchParams.statisticalArea"
            v-bind="areaNumParams"
          ></v-input-number>
          <span>㎡</span>
        </div>
        <div class="f-right">
          <v-select label="是否虚拟:" v-model="batchParams.isVirtual" :options="isVirtualOps"></v-select>
          <v-select label="建筑类型:" v-model="batchParams.buildType" :options="buildTypeOps"></v-select>
          <v-select label="房屋类型:" v-model="batchParams.houseType" :options="houseTypeOps2"></v-select>
          <v-select label="房屋状态:" v-model="batchParams.roomStatus" :options="roomStatusOps2"></v-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <v-button type @click="isBatchEdit = false" text="关 闭"></v-button>
        <v-button type="primary" @click="submitBatch" text="保 存"></v-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {
  getListURL,
  exportListURL,
  // getCommunityRommTypesURL,
  getProjectTreeURL,
  getRoomParamByNodeIdURL,
  getUpdateRoomInfoBatchCountURL,
  updateRoomInfoBatchURL,
  deleteMenuList,
} from './api'
import {
  roomStatusOps,
  roomStatusMap,
  // usingStatusMap,
  usingStatusOps,
  houseTypeMap,
  treeIconMap,
  isVirtualOps,
  buildTypeOps,
  saleTypeMap,
  saleTypeOps,
  saleStatusMap,
  saleStatusOps,
  rentTypeMap,
  rentTypeOps,
  rentStatusMap,
  rentStatusOps,
  isServerCenterOps,
  isServerCenterMap,
} from './map'
import { vTree } from 'components/control/index'
import _ from 'lodash'
import { communityParams, tenantParams } from 'common/select2Params'
import { getHouseTypeData } from 'common/networkData'

export default {
  name: 'ValidHouseList',
  components: {
    vTree,
  },
  data() {
    return {
      scrollTop:0,
      openDrawer:false,
      isServerCenterOps: isServerCenterOps(1),
      roomStatusOps: roomStatusOps(1),
      roomStatusOps2: roomStatusOps(2),
      usingStatusOps: usingStatusOps(1),
      isVirtualOps,
      buildTypeOps,
      communityParams,
      tenantParams,
      saleTypeOps: saleTypeOps(1),
      saleStatusOps: saleStatusOps(1),
      rentTypeOps: rentTypeOps(1),
      rentStatusOps: rentStatusOps(1),
      searchUrl: getListURL,
      exportUrl: exportListURL,
      houseTypeOps: [],
      statusOps: [
        {
          text: '全部',
          value: undefined,
        },
        {
          text: '开启',
          value: 1,
        },
        {
          text: '关闭',
          value: 0,
        },
      ],
      searchParams: {
        code: '',
        communityId: '',
        houseType: undefined,
        roomStatus: undefined,
        usingStatus: undefined,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        regionId: '',
        lastUpdateTimeStart: '',
        lastUpdateTimeEnd: '',
        canSell: undefined,
        isSell: undefined,
        canLease: undefined,
        isLease: undefined,
        isServerCenter: undefined,
        isParent: undefined, // 是否为子母车位
      },
      headers: [
        {
          prop: 'communityName',
          label: '项目名称',
        },
        {
          prop: 'houseTypeText',
          label: '房屋类型',
          formatter(row) {
            return houseTypeMap[row.houseType]
          },
        },
        {
          prop: 'blockName',
          label: '苑',
        },
        {
          prop: 'buildingNumber',
          label: '幢',
        },
        {
          prop: 'unit',
          label: '单元',
        },
        {
          prop: 'room',
          label: '室',
        },
        {
          prop: 'isParentText',
          label: '是否为子母车位',
          formatter(row) {
            return isServerCenterMap[row.isParent]
          },
        },
        {
          prop: 'saleTypeText',
          label: '是否可售',
          formatter(row) {
            return saleTypeMap[row.canSell]
          },
        },
        {
          prop: 'saleStatusText',
          label: '出售状态',
          formatter(row) {
            return saleStatusMap[row.isSell]
          },
        },
        {
          prop: 'rentTypeText',
          label: '是否可租',
          formatter(row) {
            return rentTypeMap[row.canLease]
          },
        },
        {
          prop: 'rentStatusText',
          label: '出租状态',
          formatter(row) {
            return rentStatusMap[row.isLease]
          },
        },
        {
          prop: 'userNameText',
          label: '房屋状态',
          formatter(row) {
            return roomStatusMap[row.roomStatus]
          },
        },
        // {
        //   prop: 'usingStatusText',
        //   label: '使用状态',
        //   formatter (row) {
        //     return usingStatusMap[row.usingStatus]
        //   }
        // },
        {
          prop: 'regionName',
          label: '所属公司',
        },
        {
          prop: 'lastUpdateTime',
          label: '最近修改时间',
        },
      ],
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'name',
        isLeaf: (data, node) => {
          return !data.isParent
        },
      },
      operaIsShow: true,
      isBatchEdit: false,
      batchNode: {
        nodeId: '',
        nodeLevel: '',
      },
      batchParams: {
        chargingArea: undefined,
        buildingArea: undefined,
        innerArea: undefined,
        publicArea: undefined,
        assistChargingArea: undefined,
        gardenArea: undefined,
        undergroundArea: undefined,
        statisticalArea: undefined,
        isVirtual: undefined,
        buildType: undefined,
        houseType: undefined,
        roomStatus: undefined,
      },
      postConfig: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      },
      areaNumParams: {
        precision: 2,
        max: 9999.99,
        min: 0.01,
      },
    }
  },
  computed: {
    houseTypeOps2() {
      let ops = _.cloneDeep(this.houseTypeOps)
      ops[0] && (ops[0].text = '请选择')
      return ops
    },
    sParams:{
      get(){
        return this.$store.state.roomTreeStore.currentParams
      },
      set(val){
        this.$store.commit('roomTreeStore/UPDATE_CURRENT',val)
      }
    }
  },
  watch:{
    sParams:{
       handler(val){
         this.selectNode(val)
        },
        immediate:true
    },
  },
  mounted() {
    console.log('parent')
    this.$refs.list.$emit('parentMounted')
    // Object.assign(this.searchParams, this.sParams)
    this.getCommunityRommTypes()
    // this.getNodeFirstList()
    let document = this.$parent.$parent.$el
    let _this = this
    document.onmousedown = function (e, a) {
      let isOpera = e.path[1].id === 'rightOpera'
      !isOpera && !_this.operaIsShow && (_this.operaIsShow = true)
    }
  },
  methods: {
    domClick() {
      console.log(1)
    },
    async getCommunityRommTypes() {
      const { houseTypeOps } = await getHouseTypeData()
      this.houseTypeOps = houseTypeOps(1)
    },
    async getNodeFirstList() {
      let list = await this.getProjectTree(0)
      this.treeData = list
      this.operaIsShow = true
    },
 
    getProjectTree(level, id) {
      let params = {
        nodeLevel: level,
      }
      id && (params.id = id)
      return new Promise((resolve, reject) => {
        this.$axios.get(getProjectTreeURL, { params }).then((res) => {
          if (res.status === 100) {
            let { data } = res
            data.forEach((val) => {
              val.icon = treeIconMap[val.iconSkin]
            })
            resolve(data)
          }
        })
      })
    },
    async treeLoad(node, resolve) {
      let { nodeLevel, id } = node.data
      let list = await this.getProjectTree(nodeLevel, id)
      setTimeout(() => {
        return resolve(list)
      }, 500)
    },
    create() {
      this.$router.push({
        name: 'validHouseForm',
      })
    },
    edit(row) {
      this.$router.push({
        name: 'validHouseForm',
        query: {
          id: row.id,
          isValidHouseList:true
        },
      })
    },
    goPageImport(router) {
      console.log(router)
      this.$router.push({
        name: router,
      })
    },
    selectNode(data) {
      let { id, name, nodeLevel } = data
      if(!id) {
        this.$nextTick(() => this.$refs.list.searchPanelSearchData());
        return
      }
      let params = {
        nodeId: id,
        nodeName: name,
        nodeLevel: nodeLevel,
      }
      this.$axios.get(getRoomParamByNodeIdURL, { params }).then((res) => {
        if (res.status === 100) {
          Object.assign(this.searchParams, res.data)
          this.$refs.list.searchPanelSearchData()
        }
      })
    },
    rightClict(event, data, node, self) {
      let { iconSkin, nodeLevel, id } = data
      const canArr = [
        'roomTreeDiyIcon3',
        'roomTreeDiyIcon4',
        'roomTreeDiyIcon5',
      ]
      if (canArr.indexOf(iconSkin) >= 0) {
        let { clientX, clientY } = event
        let operaDom = this.$refs.operaIsShow
        operaDom.style.top = `${clientY}px`
        operaDom.style.left = `${clientX}px`
        this.operaIsShow = false
        this.batchNode = {
          nodeLevel,
          nodeId: id,
        }
      }
    },
    batchEdit() {
      this.isBatchEdit = true
    },
    submitBatch() {
      let keyArr = Object.values(this.batchParams)
      let count = 0
      keyArr.forEach((val) => {
        ;(val || val === 0) && count++
      })
      if (count < 1) {
        this.$alert('请至少修改一项,再提交!')
        return
      }
      this.$axios
        .post(
          getUpdateRoomInfoBatchCountURL,
          this.$qs.stringify(this.batchNode),
          this.postConfig
        )
        .then((res) => {
          if (res.status === 100) {
            this.toSubmitBatch(res.data)
          }
        })
    },

    async toSubmitBatch(count) {
      let isOk = await this.$confirm(
        `将对 ${count} 条数据进行批量更新，是否继续执行。`
      )
      if (isOk) {
        let postData = {
          ...this.batchNode,
          ...this.batchParams,
        }
        this.handleSearchParams(postData)
        this.$axios
          .post(
            updateRoomInfoBatchURL,
            this.$qs.stringify(postData),
            this.postConfig
          )
          .then((res) => {
            if (res.status === 100) {
              this.isBatchEdit = false
              this.$refs.list.searchData()
            }
          })
      }
    },
    // 批量删除
    async batchDelete(selectedData_) {
      const ids = selectedData_.ids
      const _idsArray = Object.assign([], ids)
      const result = await this.$confirm(
        `确定删除选中的${_idsArray.length}条房号信息吗?`,
        {
          title: '提示',
        }
      )
      if (result) {
        let _that = this
        let data = {
          ids: _idsArray.toString(),
        }
        _that.$axios
          .post(deleteMenuList, this.$qs.stringify(data))
          .then((res) => {
            if (res.status === 100) {
              _that.$message.success('删除成功')
              _that.$refs.list.searchData()
              this.$refs.list.searchPanelSearchData()
              this.getNodeFirstList()
            }
          })
      }
    },
    // 点击删除
    async singerDelete(row) {
      const ids = row.id
      const result = await this.$confirm(
        `确定删除 ${row.reflectHouse} 房号信息吗?`,
        {
          title: '提示',
        }
      )
      if (result) {
        var _that = this
        let data = {
          ids,
        }
        _that.$axios
          .post(deleteMenuList, this.$qs.stringify(data))
          .then((res) => {
            if (res.status === 100) {
              _that.$message.success('删除成功')
              _that.$refs.list.searchData()
              _that.$refs.list.searchPanelSearchData()
              this.getNodeFirstList()
            }
          })
      }
    },

    handleSearchParams(postData) {
      for (let key in postData.params) {
        if (typeof postData.params[key] === 'string') {
          postData.params[key] = postData.params[key].trim()
        }
        if (postData.params[key] === null || postData.params[key] === '') {
          postData.params[key] = undefined
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.tree-group {
  width: 240px;
  height: 100%;
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  text-align: left;
}
.rightOpera {
  position: fixed;
  top: 0;
  left: 0;
  width: 116px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ddd;
  li {
    text-align: left;
    position: relative;
    display: block;
    padding: 10px 15px;
    background-color: #fff;
    color: #555;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  li:hover {
    background-color: #eaeaea;
    color: #333;
  }
}
.domHidden {
  visibility: hidden;
}
.dialog {
  text-align: left;
  .formBox {
    display: flex;
    .f-left {
      width: 260px;
      text-align: right;
      .v-control {
        margin-bottom: 15px;
        margin-right: 10px;
      }
    }
    .f-right {
      flex: 1;
      text-align: center;
      .v-control {
        margin-bottom: 15px;
      }
    }
  }
}
.dialog-footer {
  .v-button-container {
    margin-left: 10px;
  }
}
</style>
